














import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MyPageViewModel } from '../../viewmodels/my-page-viewmodel'

@Observer
@Component({
  components: {
    'post-list': () => import('@/modules/community/common/components/post-list.vue'),
    'post-grid': () => import('@/modules/community/common/components/post-grid.vue'),
  },
})
export default class MyFeed extends Vue {
  @Inject() vm!: MyPageViewModel
}
